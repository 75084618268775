body {
  margin: 0px;
  font-family: StolzlLight;
  white-space: pre-wrap;
  background-color: #000000;
  line-height: normal;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  object-fit: contain;
  color: #ffffff;
}
*, *:before, *:after {
  box-sizing: inherit;
}
h1 {
  font-family: StolzlMedium;
  font-size: 50px;
  font-weight: 500;
  line-height: 60px;
  letter-spacing: 2px;
  margin: 0;
  color: #000000;
}
h2 {
  font-family: StolzlMedium;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 2px;
  margin: 0;
  color: #000000;
}
h3 {
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 2px;
  margin: 0;
  color: #000000;
}
.content {
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 60px;
  font-size: 16px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 2px;
  color: #080707;

}

@media (max-width: 1000px) {
  .content {
    padding: 0 50px;
  }
}
@media (max-width: 625px) {
  .content {
    padding: 0 20px;
  }
}

@font-face {
  font-family: StolzlRegular;
  font-style: normal;
  font-weight: 400;
  src: url('/static/fonts/stolzl-regular.otf') format('opentype');
}
@font-face {
  font-family: StolzlLight;
  font-style: normal;
  font-weight: 400;
  src: url('/static/fonts/stolzl-light.otf') format('opentype');
}
@font-face {
  font-family: StolzlMedium;
  font-style: normal;
  font-weight: 400;
  src: url('/static/fonts/stolzl-medium.otf') format('opentype');
}
@font-face {
  font-family: StolzlBold;
  font-style: normal;
  font-weight: 400;
  src: url('/static/fonts/stolzl-bold.otf') format('opentype');
}
